import React from "react";

function Arrow({ type, className }) {
  switch (type) {
    case "down-right":
    default:
      return (
        <svg
          className={className && className}
          viewBox="0 0 177 188"
          width="177"
          height="188"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="M174.855 185.268V1.493h1.945v185.829L.828 187.3v-2.054l174.027.022Z"
            clipRule="evenodd"
          />
          <path
            fill="currentColor"
            fillRule="evenodd"
            d="m1.557.815 175 184.78-1.452 1.376-175-184.78L1.557.815Z"
            clipRule="evenodd"
          />
        </svg>
      );
    case "small":
      return (
        <svg
          className={className && className}
          viewBox="0 0 31 29"
          width="31"
          height="29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.35764 0.734741L1.35425 27.9274L30.07 27.9274M1.3557 27.928L30.0681 0.735303"
            stroke="currentColor"
            strokeMiterlimit="10"
          />
        </svg>
      );
  }
}

export default Arrow;
