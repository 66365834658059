import React from "react";

import Layout from "../components/common/Layout";
import Section from "../components/common/Section";
import Shape from "../components/common/Shape";
import Arrow from "../components/common/Arrow";
import CustomHead from "../components/common/Head";

function ContactUs() {
  return (
    <Layout>
      <Section
        title="Get in touch"
        type="secondary"
        intro="Have a question about CodeCaravan or an issue on the platform? Please let us know!"
      >
        <div className="mx-auto mt-0 md:-mt-10 lg:-mt-16 relative">
          <Shape
            type="lines"
            className="absolute -left-48 lg:-left-64 2xl:-left-72 3xl:-left-80 top-0 md:top-20 text-lime w-1/4"
          />
          <Arrow className="absolute rotate-90 right-0 -translate-y-1/2 text-lime w-24 md:w-36 lg:w-48" />
          <iframe
            src="https://us21.list-manage.com/contact-form?u=6e48324a98f4850b4e96b1835&form_id=c3f7e14c71ea493d2825ae18ed360890"
            title="Contact us form"
            className="w-[375px] sm:w-[450px] md:w-[600px] lg:w-[800px] mx-auto font-dm-sans"
            height="1100"
          />
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  const pageMeta = {
    title: "Contact us",
    description:
      "Have a question about CodeCaravan or an issue on the platform? Please let us know! We'd love to hear from you.",
  };

  return <CustomHead pageMeta={pageMeta} />;
};

export default ContactUs;
